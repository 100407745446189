import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

const AuthGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const token = sessionStorage.getItem('resData');
    const accounttype = sessionStorage.getItem('resDataaccounttype');
    if (!token && !isLoggedIn && accounttype == 'admin') {
        return <Redirect to="/login" />;
    } 
    if (!token && !isLoggedIn && accounttype != 'admin') { 
        return <Redirect to="/customer/login" />;
    }

    return children;
};

export default AuthGuard;
