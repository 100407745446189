import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Button, Tooltip } from '@material-ui/core';

import Brightness6OutlinedIcon from '@material-ui/icons/Brightness6Outlined';
import Brightness6Icon from '@material-ui/icons/Brightness6';

import * as actionTypes from '../../../../store/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '350px',
        minWidth: '250px',
        backgroundColor: theme.palette.background.paper,
        paddingBottom: 0,
        borderRadius: '10px',
    },
    subHeader: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.common.white,
        padding: '5px 15px',
    },
    menuIcon: {
        fontSize: '1.5rem',
    },
    gridContainer: {
        padding: '10px',
    },
    formContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    menuButton: {
        [theme.breakpoints.down('xs')]: {
            minWidth: '35px',
        },
    },
}));

const Customization = () => {
    const classes = useStyles();
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(customization.navType === 'light');

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    useEffect(() => {
        dispatch({ type: actionTypes.MENU_TYPE, navType: open ? 'light' : 'dark' });
    }, [dispatch, open]);

    return (
        <Tooltip title={open ? 'Dark Layout' : 'Light Layout'}>
            <Button className={classes.menuButton} onClick={handleToggle} color="black">
                {!open && <Brightness6Icon className={classes.menuIcon} />}
                {open && <Brightness6OutlinedIcon className={classes.menuIcon} />}
            </Button>
        </Tooltip>
    );
};

export default Customization;
